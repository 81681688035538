import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './Components.scss'
import { useNavigate } from "react-router-dom"
import {useSelector} from 'react-redux'

function DropDown() {
  const navigate = useNavigate();
  const dataSelect = useSelector(state => state.logData);
  let data=dataSelect[dataSelect.length-1]
  const maps = (e) => {
    navigate('/maps')
  }
  const showrequests = (e) => {
    navigate('/show-requests')
  }
  const requests = (e) => {
    navigate('/requests')
  }
  const gardens = (e) => {
    navigate('/gardens')
  }
  const registerManager = (e) => {
    navigate('/register-manager')
  }
  const admin = (e) => {
    navigate('/admin')
  }
  const managers = (e) => {
    navigate('/managers')
  }
  const pools = (e) => {
    navigate('/pools')
  }
  const security = (e) => {
    navigate('/security')
  }
  const addVisitor = (e) => {
    navigate('/add-visitor')
  }
  const serviceList = (e) => {
    navigate('/services-list')
  }
  const owner = (e) => {
    navigate('/owner')
  }
  const visitors = (e) => {
    navigate('/visitors')
  }
  const rentals = (e) => {
    navigate('/rentals')
  }
  const addServices = (e) => {
    navigate('/add-services')
  }
  const addPools = (e) => {
    navigate('/add-pools')
  }
  const AddAmmenities = (e) => {
    navigate('/add-ammenities')
  }
  const adminService = (e) => {
    navigate('/admin-service')
  }
  const residentsDashboard = (e) => {
    navigate('/residents-dashboard')
  }
  const residentsGarden = (e) => {
    navigate('/residents-gardens')
  }
  const residentsPools = (e) => {
    navigate('/residents-pools')
  }
  return (
    <DropdownButton id="dropdown-basic-button" title="" className='drop'>
      <Dropdown.Item onClick={maps}>Maps</Dropdown.Item>
      {data.type==='resident' && <Dropdown.Item onClick={requests}>Requests</Dropdown.Item>}
      {data.type==='resident' && <Dropdown.Item onClick={showrequests}>All Requests</Dropdown.Item>}
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={gardens}>Gardens</Dropdown.Item>}
      {data.name==='Garden Manager       ' && <Dropdown.Item onClick={gardens}>Gardens</Dropdown.Item>}
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={admin}>Admin</Dropdown.Item>}
      <Dropdown.Item onClick={managers}>Managers</Dropdown.Item>
      <Dropdown.Item onClick={visitors}>Visitors</Dropdown.Item>
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={pools}>Pools</Dropdown.Item>}
      {data.name==='Pool Manager       '  && <Dropdown.Item onClick={pools}>Pools</Dropdown.Item>}
      {data.type==='manager' && <Dropdown.Item onClick={security}>security</Dropdown.Item>}
      {data.type==='resident' && <Dropdown.Item onClick={addVisitor}>Add Visitor</Dropdown.Item>}
      {data.type==='resident' && <Dropdown.Item onClick={serviceList}>Service List</Dropdown.Item>}
      {data.type==='resident' && <Dropdown.Item onClick={residentsDashboard}>Resident Dashboard</Dropdown.Item>}
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={residentsGarden}>Resident Garden</Dropdown.Item>}
      {data.type==='resident' && <Dropdown.Item onClick={residentsPools}>Resident Pools</Dropdown.Item>}
      <Dropdown.Item onClick={owner}>Owner Dashboard</Dropdown.Item>
      {data.type==='resident' && <Dropdown.Item onClick={rentals}>Rentals</Dropdown.Item>}
      {data.name==='Building Manager       '  && <Dropdown.Item onClick={addServices}>Add Services</Dropdown.Item>}
      {data.name==='Pool Manager       '  && <Dropdown.Item onClick={addPools}>Add Pools</Dropdown.Item>}
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={addPools}>Add Pools</Dropdown.Item>}
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={AddAmmenities}>Add Ammenities</Dropdown.Item>}
      {data.name==='Building Manager       ' && <Dropdown.Item onClick={adminService}>Admin Services</Dropdown.Item>}
    </DropdownButton>
  );
}

export default DropDown;