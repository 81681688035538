import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import {Link, useNavigate} from "react-router-dom"
import Button from './components/Button';
import { useState, useEffect} from "react"
import axios from 'axios'
import { mdiDeleteCircle } from '@mdi/js';
import { mdiImageEditOutline } from '@mdi/js';
import $ from "jquery"
import Icon from '@mdi/react'
function Security({
}) {
    const [result, setResult] = useState("");
    const [security, getsecuritys]=useState([])
    const navigate = useNavigate();
    const addSecurity = (e) => {
        navigate('/add-security')
      }
    const getsecurity = () => {
        axios.get('https://bxy4670.uta.cloud/backend/security-list.blade.php').then((response)=>{
            getsecuritys(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getsecurity();
    },[]);
    const deletesecurity = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        getsecurity();
    }
    let dummy=[['Paul','abc pool','Pool','xyz'],['Mathew','new garden','Garden','jkl'],['John','123 building','Building','xyz']]
    return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Security
        </div>
    </div>
    <div className='services-list'>
        <table className='table1'>
            <tr>
                <th>Security name</th>
                <th>Building / asset name</th>
                <th>Incharge for</th>
                <th>Community</th>
                <th></th>
            </tr>
            {security.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>
                        <span title='edit'>
                            <Link to='../edit-security' state={{name:data[0],asset:data[1],community:data[3],incharge:data[2]}}><button type='submit' style={{border:'none'}}><Icon path={mdiImageEditOutline} style={{height:'24px',cursor:'pointer'}} /></button></Link>
                        </span>
                        <form action="https://bxy4670.uta.cloud/backend/delete-security.blade.php" method="post" onSubmit={(event) => deletesecurity(event)}><span title='delete'><input style={{display:'none'}} name='name' value={data[0]}/><button type='submit' style={{border:'none'}}><Icon path={mdiDeleteCircle} style={{height:'24px',cursor:'pointer',color:'red'}} /></button></span></form>
                    </td>
                </tr>
                );
            })}
            <Button onClick={addSecurity} text='Add Security'/>
        </table>
    </div>
  </>
  );
}

export default Security;
