import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect, useCallback } from "react"
import $ from "jquery"

function Requests({
}) {
    const [name, setName] = useState("")
    const [result, setResult] = useState("")
    const [email, setEmail] = useState("")
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const [number, setNumber] = useState("")
    const [details, setDetails] = useState("")
    const handleNumber = (e) => {
        setNumber(e.target.value);
    }
    const handleDetails = (e) => {
        setDetails(e.target.value);
    }
    const handleSumbit = (e) => {
        e.preventDefault()
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        alert('Incident created successfully')
      }
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Requests or Incidents
        </div>
    </div>
    <div className='login-wrapper'>
        <form onSubmit={(event) => handleSumbit(event)}  action="https://bxy4670.uta.cloud/backend/incident.blade.php"
            method="post">
            <div className='login forgot-password'>
                <h2>Register Incidents</h2>
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required/>
                <Input type='email' placeholder='Email' name='emailId' value={email} onChange={(event) => handleEmail(event)} required />
                <Input type='number' placeholder='Phone Number' name='number' value={number} onChange={(event) => handleNumber(event)} required />
                <textarea placeholder='Request or Incident Details' name='details' value={details} onChange={(event) => handleDetails(event)} required />
                <Button text='Submit' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default Requests;
