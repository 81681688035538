import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import Label from './components/Label';
import { useState } from "react"
import emailjs from "emailjs-com"
import $ from "jquery"
function Contact({
}) {
  const [result, setResult] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const handleName = (e) => {
    setName(e.target.value)
  }
  const handleEmail = (e) => {
      setEmail(e.target.value)
  }
  const handleMessage = (e) => {
      setMessage(e.target.value)
  }
  const handleSumbit = (e) => {
    e.preventDefault()
    emailjs.send("service_uk5hmm4","template_awa9p6r",{
        name: name,
        email: email,
        message: message,
        },'QyjLhYruDuTfG0qSO')
    .then((result) => {
        console.log(result.text)
    }, (error) => {
        console.log(error.text)
    })
    const form = $(e.target);
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success(data) {
            setResult(data);
        },
    });
    alert('Query submitted successfully. Your query will be answered in max 48 hours')
  }
  return (
  <>
    <Header/>
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Contact
        </div>
    </div>
    <form className='contact-body' onSubmit={(event) => handleSumbit(event)} style={{display:'flex',justifyContent:'center'}}>
          <div className='inner-div'>
            <Label text='If you have any queries please contact us. Our support team will respond to you in 24-48 hours'/>
            <div>
                <Input placeholder='First Name' name="name" value={name} onChange={(event) => handleName(event)} required />
                <Input placeholder='Last Name'/>
            </div>
            <div>
                <Input placeholder='Phone Number' type='number' required/>
                <Input placeholder='Email' type='email'  name='emailId' onChange={(event) => handleEmail(event)} value={email} required/>
            </div>
            <Input placeholder='Subject' required />
            <textarea placeholder='Query'  name='message' onChange={(event) => handleMessage(event)} value={message} required/>
            <Button text='Submit' type='submit'/>
          </div>
    </form>
  </>
  );
}

export default Contact;
