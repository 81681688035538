import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect } from "react"
import Home from './views/Home'
import Login from './views/Login'
import About from './views/About'
import Contact from './views/Contact'
import MercadoEscolar from "./views/MercadoEscolar"
import Services from "./views/Services"
import ForgotPassword from "./views/ForgotPassword"
import Admin from "./views/Admin"
import BusinessProfile from "./views/BusinessProfile"
import Chatbot from './views/Chatbot'
import EditProfile from "./views/EditProfile"
import Maps from "./views/Maps"
import Requests from "./views/Requests"
import RegisterManager from "./views/RegisterManager"
import AddVisitor from "./views/AddVisitor"
import ServicesList from "./views/ServicesList"
import Gardens from "./views/Gardens"
import Security from "./views/security"
import Owner from "./views/Owner"
import Managers from "./views/managers"
import Pools from "./views/pools"
import Rentals from "./views/RentalList"
import AddServices from "./views/AddServices"
import AddAmenities from "./views/AddAmenities"
import AdminServiceDashboard from "./views/AdminServiceDashboard"
import EditServices from "./views/EditService"
import EditAmmenities from "./views/EditAmmenities"
import AddPools from "./views/AddPool"
import EditPools from "./views/EditPool"
import AddGardens from "./views/AddGardens"
import EditGardens from "./views/EditGardens"
import Visitors from "./views/Visitors"
import AddSecurity from "./views/AddSecurity"
import EditSecurity from "./views/EditSecurity"
import ResidentsDashboard from "./views/ResidentsDashboard"
import ResidentsGardens from "./views/ResidentsGardens"
import ResidentsPools from "./views/ResidentsPools"
import AddRentals from "./views/AddRentals"
import EditRentals from "./views/EditRentals"
import ShowRequests from "./views/ShowRequests"
function App() {
  useEffect(() => {
    document.title = 'Terrazas de Guacuco'
}, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" component={<MercadoEscolar />}>
          <Route index element={<MercadoEscolar />} />
          <Route path='home' element={<MercadoEscolar/>}/>
          <Route path='login' element={<Login/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='services' element={<Services/>}/>
          <Route path='edit-profile' element={<EditProfile/>}/>
          <Route path='chatbot' element={<Chatbot/>}/>
          <Route path='forgot-password' element={<ForgotPassword/>}/>
          <Route path='admin' element={<Admin/>}/>
          <Route path='business-profile' element={<BusinessProfile/>}/>
          <Route path='maps' element={<Maps/>}/>
          <Route path='requests' element={<Requests/>}/>register-manager
          <Route path='register-manager' element={<RegisterManager/>}/>
          <Route path='add-visitor' element={<AddVisitor/>}/>
          <Route path='add-services' element={<AddServices/>}/>
          <Route path='add-ammenities' element={<AddAmenities/>}/>
          <Route path='add-gardens' element={<AddGardens/>}/>
          <Route path='services-list' element={<ServicesList/>}/>
          <Route path='admin-service' element={<AdminServiceDashboard/>}/>
          <Route path='gardens' element={<Gardens/>}/>
          <Route path='owner' element={<Owner/>}/>
          <Route path='visitors' element={<Visitors/>}/>
          <Route path='pools' element={<Pools/>}/>
          <Route path='security' element={<Security/>}/>
          <Route path='edit-service' element={<EditServices/>}/>
          <Route path='residents-dashboard' element={<ResidentsDashboard/>}/>
          <Route path='residents-gardens' element={<ResidentsGardens/>}/>
          <Route path='edit-security' element={<EditSecurity/>}/>
          <Route path='edit-pool' element={<EditPools/>}/>
          <Route path='edit-rental' element={<EditRentals/>}/>
          <Route path='edit-gardens' element={<EditGardens/>}/>
          <Route path='add-pools' element={<AddPools/>}/>
          <Route path='add-rentals' element={<AddRentals/>}/>
          <Route path='edit-ammenities' element={<EditAmmenities/>}/>
          <Route path='show-requests' element={<ShowRequests/>}/>
          <Route path='add-security' element={<AddSecurity/>}/>
          <Route path='managers' element={<Managers/>}/>
          <Route path='rentals' element={<Rentals/>}/>
          <Route path='residents-pools' element={<ResidentsPools/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
