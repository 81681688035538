import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect} from "react"
import $ from "jquery"
import { useNavigate } from 'react-router-dom';

function AddServices({
}) {
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [month, setmonth] = useState("")
    const [cost, setcost] = useState("")
    const [tax, setTax] = useState("")
    const [result, setResult] = useState("");
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handlemonth = (e) => {
        setmonth(e.target.value);
    }
    const handlecost = (e) => {
        setcost(e.target.value);
    }
    const handleTax = (e) => {
        setTax(e.target.value);
    }
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
    };
    useEffect(() => {
        if(result==='true'){
            alert('Service Added successfully')
            setName("")
            setcost("")
            setTax("")
            setmonth("")
            navigate('../admin')
        }
        if(result==='false') {
            alert("Failed to add")
        }
    },[ result])
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Add Services
        </div>
    </div>
    <div className='login-wrapper'>
        <form  onSubmit={(event) => handleSumbit(event)}
            action="https://bxy4670.uta.cloud/backend/add-service.blade.php"
            method="post" >
            <div className='login forgot-password'>
                <h2>Add Services</h2> 
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required />
                <Input type='number' placeholder='Cost' name="cost" value={cost} onChange={(event) => handlecost(event)} required />
                <Input type='number' placeholder='Tax' name="tax" value={tax} onChange={(event) => handleTax(event)} required />
                <Input type='text' placeholder='Month' name="month" value={month} onChange={(event) => handlemonth(event)} required />
                <Button text='Add Services' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default AddServices;
