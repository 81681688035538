import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect} from "react"
import axios from 'axios'
function ShowRequests({
    hide
}) {
    const [manager, getmanagers]=useState([])
    const getmanager = () => {
        axios.get('https://bxy4670.uta.cloud/backend/incident-list.blade.php').then((response)=>{
            getmanagers(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getmanager();
    },[]);
    let dummy=[['Paul','Pool Manager','abc pool','xyz'],['Mathew','Garden Manager','new garden','jkl'],['John','Building Manager','123 building','xyz']]
    return (
  <>
    { !hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Requests
        </div>
    </div></>}
    <div className='services-list'>
        <table className='table1'>
            <tr>
                <th>Incident Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Details</th>
            </tr>
            {manager.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                </tr>
                );
            })}
        </table>
    </div>
  </>
  );
}

export default ShowRequests;
