import React, { useState } from 'react';
import {
    FaBars,
}from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import "./app.css"
import {useSelector, useDispatch} from 'react-redux'
import {logIn} from '../../log'
const Sidebar = ({children}) => {
    const dataSelect = useSelector(state => state.logData);
    let data=dataSelect[dataSelect.length-1]
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const normalmenuItem=[ 
        {
            path:"/owner",
            name:"Owner Dashboard",
        },
        {
            path:"/maps",
            name:"Maps",
        },
        {
            path:"/managers",
            name:"Managers",
        },
        {
            path:"/visitors",
            name:"Visitors",
        }
    ]
    const residentMenuItem=[ 
        {
            path:"/owner",
            name:"Owner Dashboard",
        },
        {
            path:"/maps",
            name:"Maps",
        },
        {
            path:"/managers",
            name:"Managers",
        },
        {
            path:"/visitors",
            name:"Visitors",
        },
        {
            path:"/requests",
            name:"Requests",
        },
        {
            path:"/show-requests",
            name:"All Requests",
        }
    ]
    const buildingManager=[ 
        {
            path:"/owner",
            name:"Owner Dashboard",
        },
        {
            path:"/maps",
            name:"Maps",
        },
        {
            path:"/managers",
            name:"Managers",
        },
        {
            path:"/visitors",
            name:"Visitors",
        },
        {
            path:"/admin-service",
            name:"Admin Services",
        },
        {
            path:"/add-services",
            name:"Add Services",
        },
        {
            path:"/add-ammenities",
            name:"Add Ammenities",
        },
        {
            path:"/admin",
            name:"Admin",
        },
        {
            path:"/security",
            name:"Security",
        }
    ]
    const poolmanager=[ 
        {
            path:"/owner",
            name:"Owner Dashboard",
        },
        {
            path:"/maps",
            name:"Maps",
        },
        {
            path:"/managers",
            name:"Managers",
        },
        {
            path:"/visitors",
            name:"Visitors",
        },
        {
            path:"/pools",
            name:"Pools",
        },
        {
            path:"/add-pools",
            name:"Add Pools",
        }
    ]
    const gardenmanager=[ 
        {
            path:"/owner",
            name:"Owner Dashboard",
        },
        {
            path:"/maps",
            name:"Maps",
        },
        {
            path:"/managers",
            name:"Managers",
        },
        {
            path:"/visitors",
            name:"Visitors",
        },
        {
            path:"/gardens",
            name:"Gardens",
        },
        {
            path:"/add-gardens",
            name:"Add Gardens",
        }
    ]
    const security=[ 
        {
            path:"/owner",
            name:"Owner Dashboard",
        },
        {
            path:"/maps",
            name:"Maps",
        },
        {
            path:"/managers",
            name:"Managers",
        },
        {
            path:"/visitors",
            name:"Visitors",
        },
        {
            path:"/security",
            name:"Security",
        }
    ]
    var menuItem
    if(data.type==='resident') {
        menuItem=residentMenuItem
    }
    else if(data.name==='Building Manager       ') {
        menuItem=buildingManager
    }
    else if(data.name==='Pool Manager       ' ) {
        menuItem=poolmanager
    }
    else if(data.name==='Garden Manager       ' ) {
        menuItem=gardenmanager
    }
    else if(data.name==='Security Manager       ' ) {
        menuItem=security
    }
    else{
        menuItem=normalmenuItem
    }
    return (
        <div className="container">
           <div style={{width: isOpen ? "200px" : "50px",height: isOpen ? '100%' : "50px"}} className="sidebar">
               <div className="top_section">
                   <h1 style={{display: isOpen ? "block" : "none"}} className="logo">{data.name}</h1>
                   <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
           </div>
           <main>{children}</main>
        </div>
    );
};

export default Sidebar;