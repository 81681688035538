import './pages.scss'
import Header from './components/Header'
import Input from './components/Input'
import Button from './components/Button'
import { useState, useEffect} from "react"
import $ from "jquery"
import Icon from '@mdi/react'
import axios from 'axios'
import {Link, useNavigate} from "react-router-dom"
import { mdiDeleteCircle } from '@mdi/js'
import { mdiImageEditOutline } from '@mdi/js'
function Rentals({hide
}) {
    const [result, setResult] = useState("")
    const navigate = useNavigate()
    const addGardens = (e) => {
        navigate('/add-rentals')
      }
    const [garden, getgardens]=useState([])
    const getgarden = () => {
        axios.get('https://bxy4670.uta.cloud/backend/rental-list.blade.php').then((response)=>{
            getgardens(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getgarden()
    },[])
    const deleteGarden = (e) => {
        e.preventDefault()
        const form = $(e.target)
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data)
            },
        })
        getgarden()
    }
    let dummy=[['Emili','440','20-09-2022','600'],['Emili','440','20-09-2022','600'],['Emili','440','20-09-2022','600'],['Emili','440','20-09-2022','600']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Rentals
        </div>
    </div></>}
    <div className='services-list'>
        <table className='table1'>
        <Button onClick={addGardens} text='Add Rentals'/>
            <tr>
                <th>Name</th>
                <th>Building no</th>
                <th>Staying from</th>
                <th>Total due</th>
                <th></th>
            </tr>
            {garden.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>
                        <span title='edit'>
                            <Link to='../edit-rental' state={{name:data[0],timings:data[1],community:data[2],size:data[3],cost:data[4]}}><button type='submit' style={{border:'none'}}><Icon path={mdiImageEditOutline} style={{height:'24px',cursor:'pointer'}} /></button></Link>
                        </span>
                        <form action="https://bxy4670.uta.cloud/backend/delete-rental.blade.php" method="post" onSubmit={(event) => deleteGarden(event)}><span title='delete'><input style={{display:'none'}} name='name' value={data[0]}/><button type='submit' style={{border:'none'}}><Icon path={mdiDeleteCircle} style={{height:'24px',cursor:'pointer',color:'red'}} /></button></span></form>
                    </td>
                </tr>
                )
            })}
        </table>
    </div>
  </>
  )
}

export default Rentals
