import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect, useCallback } from "react"
import emailjs from "emailjs-com"
import { useNavigate } from "react-router-dom";
import $ from "jquery"

function RegisterManager({
}) {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [dob, setDob] = useState("")
    const [address, setAddress] = useState("")
    const [phone, setphone] = useState("")
    const [gender, setGender] = useState("")
    const [type, settype] = useState("")
    const navigate = useNavigate();
    const handleGender = (e) => {
        setGender(e.target.value);
    }
    const handleAddress = (e) => {
        setAddress(e.target.value);
    }
    const handlePhone = (e) => {
        setphone(e.target.value);
    }
    const handlePassword = (e) => {
        setPassword(e.target.value);
    }
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const handletype = (e) => {
        settype(e.target.value);
    }
    const handleDob = (e) => {
        setDob(e.target.value);
    }
    const [result, setResult] = useState("");
    const handleSumbit = (e) => {
        e.preventDefault();
        emailjs.send("service_uk5hmm4","template_08j1xdq",{
            name: name,
            email: email,
            },'QyjLhYruDuTfG0qSO')
        .then((result) => {
            console.log(result.text)
        }, (error) => {
            console.log(error.text)
        })
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        };
    useEffect(() => {
        if(result==='true')
            navigate('/home')
        if(result==='false')
            alert('Registration failed')
        if(result==='user already registered')
            alert(result)
    },[ result])
  return (
  <>
    <Header />
    <div className='login-wrapper'>
        <form onSubmit={(event) => handleSumbit(event)}
            action="https://bxy4670.uta.cloud/backend/manager-signup.blade.php"
            method="post" >
            <div className='login forgot-password'>
                <h2>Register Manager</h2> 
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required/>
                <Input type='date' placeholder='Date of birth' name="dob" value={dob} onChange={(event) => handleDob(event)} required/>
                <Input type='number' placeholder='Phone Number' name="phone" value={phone} onChange={(event) => handlePhone(event)} required/>
                <Input type='email' placeholder='Email' name='emailId' value={email} onChange={(event) => handleEmail(event)}  required/>
                <Input type='password' placeholder='Password' name='password' onChange={(event) => handlePassword(event)} value={password} required/>
                <Input type='password' placeholder='Confirm Password' onChange={(event) => handleConfirmPassword(event)} value={confirmPassword} pattern={password} title='Both the password and confirm password fields value must be matched' required/>
                <Input type='text' placeholder='Address' name="address" value={address} onChange={(event) => handleAddress(event)} required/>
                <select  required name="gender">
                    <option hidden value=' ' disabled selected >Gender</option>
                    <option onChange={(event) => handleGender(event)} value='Male'>Male</option>
                    <option onChange={(event) => handleGender(event)} value='Female'>Female</option>
                    <option onChange={(event) => handleGender(event)} value='Other'>Other</option>
                </select>
                <select name='type' required>
                    <option hidden value=' ' disabled selected>Type of Manager</option>
                    <option onChange={(event) => handletype(event)} value='Pool Manager'>Pool Manager</option>
                    <option onChange={(event) => handletype(event)} value='Garden Manager'>Garden Manager</option>
                    <option onChange={(event) => handletype(event)} value='Building Manager'>Building Manager</option>
                    <option onChange={(event) => handletype(event)} value='Security Manager'>Security Manager</option>
                </select>
                <Button text='Register' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default RegisterManager;
