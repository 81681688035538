import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect, useCallback } from "react"
import $ from "jquery"
import { useNavigate } from 'react-router-dom';

function AddVisitor({
}) {
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [vehicle, setVehicle] = useState("")
    const [dl, setDl] = useState("")
    const [dob, setDob] = useState("")
    const [model, setModel] = useState("")
    const [phone, setGender] = useState("")
    const [result, setResult] = useState("");
    const [time, setTime] = useState("")
    const handlePhone = (e) => {
        setGender(e.target.value);
    }
    const handleModel = (e) => {
        setModel(e.target.value);
    }
    const handleVehicle = (e) => {
        setVehicle(e.target.value);
    }
    const handleDl = (e) => {
        setDl(e.target.value);
    }
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const handleTime = (e) => {
        setTime(e.target.value);
    }
    const handleDob = (e) => {
        setDob(e.target.value);
    }
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        navigate('../visitors')
    };
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Add Visitor
        </div>
    </div>
    <div className='login-wrapper'>
        <form onSubmit={(event) => handleSumbit(event)}
            action="https://bxy4670.uta.cloud/backend/add-visitor.blade.php"
            method="post" >
            <div className='login forgot-password'>
                <h2>Add Visitor</h2> 
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required/>
                <Input type='date' placeholder='Date of birth' name="dob" value={dob} onChange={(event) => handleDob(event)} required/>
                <Input type='text' placeholder='Time' name="time" value={time} onChange={(event) => handleTime(event)} required/>
                <Input type='email' placeholder='Email' name="email" value={email} onChange={(event) => handleEmail(event)} required/>
                <Input type='number' placeholder='Phone Number' name="phone" value={phone} onChange={(event) => handlePhone(event)} required/>
                <Input type='text' placeholder='Model' name="model" value={model} onChange={(event) => handleModel(event)} required/>
                <Input type='text' placeholder='Vehicle' name="vehicle" value={vehicle} onChange={(event) => handleVehicle(event)} required/>
                <Input type='text' placeholder='Driving Licence' name="dl" value={dl} onChange={(event) => handleDl(event)} required/>
                <Button text='Add Visitor' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default AddVisitor;
