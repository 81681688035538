import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect} from "react"
import $ from "jquery"
import { useNavigate } from 'react-router-dom';

function AddPools({
}) {
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [inchargeName, setinchargeName] = useState("")
    const [timings, settimings] = useState("")
    const [reservedBy, setreservedBy] = useState("")
    const [result, setResult] = useState("");
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleinchargeName = (e) => {
        setinchargeName(e.target.value);
    }
    const handletimings = (e) => {
        settimings(e.target.value);
    }
    const handlereservedBy = (e) => {
        setreservedBy(e.target.value);
    }
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
    };
    useEffect(() => {
        if(result==='true'){
            alert('Pool Added successfully')
            setName("")
            settimings("")
            setreservedBy("")
            setinchargeName("")
            navigate('../pools')
        }
        if(result==='false') {
            alert("Failed to add")
        }
    },[ result])
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Add Pools
        </div>
    </div>
    <div className='login-wrapper'>
        <form  onSubmit={(event) => handleSumbit(event)}
            action="https://bxy4670.uta.cloud/backend/add-pools.blade.php"
            method="post" >
            <div className='login forgot-password'>
                <h2>Add Pools</h2> 
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required />
                <Input type='text' placeholder='Timings' name="timings" value={timings} onChange={(event) => handletimings(event)} required />
                <Input type='text' placeholder='Reserved By' name="reservedBy" value={reservedBy} onChange={(event) => handlereservedBy(event)} required />
                <Input type='text' placeholder='Incharge Name' name="inchargeName" value={inchargeName} onChange={(event) => handleinchargeName(event)} required />
                <Button text='Add Pools' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default AddPools;
