import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect} from "react"
import $ from "jquery"
import { useNavigate } from 'react-router-dom';

function AddSecurity({
}) {
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [communiy, setcommuniy] = useState("")
    const [assetName, setassetName] = useState("")
    const [incharge, setincharge] = useState("")
    const [result, setResult] = useState("");
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handlecommuniy = (e) => {
        setcommuniy(e.target.value);
    }
    const handleassetName = (e) => {
        setassetName(e.target.value);
    }
    const handleincharge = (e) => {
        setincharge(e.target.value);
    }
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
    };
    useEffect(() => {
        if(result==='true'){
            alert('Security Added successfully')
            setName("")
            setassetName("")
            setincharge("")
            setcommuniy("")
            navigate('../security')
        }
        if(result==='false') {
            alert("Failed to add")
        }
    },[ result])
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Add Security
        </div>
    </div>
    <div className='login-wrapper'>
        <form  onSubmit={(event) => handleSumbit(event)}
            action="https://bxy4670.uta.cloud/backend/add-security.blade.php"
            method="post" >
            <div className='login forgot-password'>
                <h2>Add Security</h2> 
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required />
                <Input type='text' placeholder='Asset Name' name="assetName" value={assetName} onChange={(event) => handleassetName(event)} required />
                <Input type='text' placeholder='Incharge' name="incharge" value={incharge} onChange={(event) => handleincharge(event)} required />
                <Input type='text' placeholder='Communiy' name="communiy" value={communiy} onChange={(event) => handlecommuniy(event)} required />
                <Button text='Add Security' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default AddSecurity;
